@font-face {
  font-family: 'KaushanScript';
  src: local('KaushanScript'), url('./assets/KaushanScript-Regular.ttf') format('truetype');
}

.css-6hp17o-MuiList-root-MuiMenu-list {
 padding: 0 !important;
 
}


